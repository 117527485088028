@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;600;700;800&display=swap');

@import "./node_modules/bootstrap/scss/bootstrap";

@import "variable";
@import "./icons/icons";
@import "./components/button";
@import "../vendor/_fullpage.css";


$topbar__height: 70px;
$header__height: $topbar__height;


@mixin transition($type, $duration: 0.5s) {
  -webkit-transition: $type $duration;
  -moz-transition: $type $duration;
  -ms-transition: $type $duration;
  -o-transition: $type $duration;
  transition: $type $duration;
}

body {
  background-color: $body_bg;
  font-family: $font_primary;
  font-weight: $font_medium;
  font-size: $font_size_base;
  line-height: $font_base_line_height;
  overflow-x: hidden;

  @media(max-width: $breakpoint__medium){
    padding-top: 89px !important;
  }
}

%setUlCss {
  padding-left: 0;
  list-style: none;
}

.topbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: $gutter__space;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  @include transition(ease,0.3s);

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  @media(max-width: $breakpoint__medium) {
    height: auto;
    padding: $gutter__space;
    position: relative;
    background-color: $secondary_color;

    top: unset !important;
  }
  $this: &;

  &__links {
    @extend %setUlCss;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    color: $white;
    margin: 0;
    padding: 0 $topbar__height;

    @media(max-width: $breakpoint__medium) {
      padding: 0;
    }
  }

  &__links__item {
    flex: 1;

    @media(max-width: $breakpoint__medium) {
      flex: auto;
      padding: $gutter__space;
    }

    span {
      display: block;
      text-transform: uppercase;
      font-weight: $font-bold;
      text-align: center;
      line-height: $font_base_line_height;

      &:nth-child(1) {
        font-weight: $font_extra_light;
        font-size: 0.9em;
      }

      a
      {
        color: inherit;
        text-transform: lowercase;
      }
    }
  }

  .navbar-brand,
  button {
    @media(max-width: $breakpoint__medium) {
      display: none;
    }
  }
}


main {
  height: auto;
}
.banner {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .carousel-inner {
    background-color: $gray__dark;
  }

  .carousel__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    color: $white;
    text-align: center;
    max-width: $breakpoint__small;
    line-height: 1.2;

    $this: &;

    @media(min-width: $breakpoint__extra__large) {
      max-width: $breakpoint__large;
    }


    @media(max-width: $breakpoint__medium) {
      padding-left: $gutter__space * 2;
      padding-right: $gutter__space * 2;
    }

    &__heading {
      font-size: 1.8em;
      font-weight: $font-extra_bold;
      text-transform: capitalize;
      margin-bottom: $gutter__space/2;

      @media(min-width: $breakpoint__extra__large) {
        font-size: $heading__large;
      }
    }

    + img {
      height: $main__height;
      object-fit: cover;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 1px solid;
    margin: 0 -60px;
    top: 80%;
    z-index: 10;
  }

  .carousel-control-prev {
    left: 50%;
  }

  .carousel-control-next {
    right: 50%;
  }
}


header {

  .navbar-collapse
  {
    justify-content: center;
  }

  nav.navbar {
    //transition-delay: 1s;
    @include transition(all);
    min-height: $header__height;
    background-color: $secondary_color;
    align-items: stretch;
    padding: 0;
    width: 100%;
    z-index: 100;
    position: fixed;
    top: calc(100vh - 70px);
    overflow: hidden;
    @media(max-width: $breakpoint__medium) {
      top: 0px;
    }

    @media(max-width: $breakpoint__medium) {
      position: fixed;
      bottom: auto;
      top: 0px;
      padding: $gutter__space;
    }
    .container {
      @media(max-width: $breakpoint__medium) {
        display: grid;
        grid-template-columns: 1fr 150px auto;
        grid-gap: 15px;
      }
    }

    .navbar-toggler {
      @media(max-width: $breakpoint__medium) {
        border: 0 !important;
        color: $white;
        border-radius: 0;
        padding: 0;
        outline: none;
        grid-column: 3/3;
        .navbar-toggler-icon {
          height: auto;
          width: auto;
        }
        svg {
          font-size: 2em;
        }
      }
    }

    .navbar-brand {
      @media(max-width: $breakpoint__medium) {
        flex: 1;
      }

      img {
        max-width: 120px;
      }
    }

    .navbar-brand,
    button.btn-primary {
      bottom: -100px;
      position: relative;
      @include transition(ease-in-out);
      @media(max-width:$breakpoint__medium) {
        bottom: 0;
      }
    }

    .navbar-brand {
      transition-delay: 0.2s;
      @media(max-width: $breakpoint__medium) {
        width: 100px;
      }
    }
    button.btn-primary {
      transition-delay: 0.3s;
      @media(max-width: $breakpoint__medium) {
        width: auto;
        grid-column: 2/3;
        grid-row: 1;
      }
    }
    .mainNavigation {
      height: 100%;
      @media(max-width: $breakpoint__medium) {
        grid-column: 1/4;
      }
    }
    .navbar-nav {
      @extend %setUlCss;
      @media(min-width: $breakpoint__medium) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        height: 100%;
      }

      @media(max-width: $breakpoint__medium) {
        margin-top: $gutter__space;
      }

      li > a {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: $font_semi_bold;

        padding-left: 1rem !important;
        padding-right: 1rem !important;

        @media(min-width: $breakpoint__large) {
          padding-left: $gutter__space * 3 !important;
          padding-right: $gutter__space * 3 !important;
        }
      }

      li {
        &:nth-child(1) {
          @media (max-width: $breakpoint__extra__large) {
            display: none;
          }
        }
      }

      .nav-item.active > a,
      .nav-item > a:hover {
        background-color: $primary_color;
      }
    }
  }
}
.banner,
.carousel,
.carousel-inner,
.carousel-item,
.banner .carousel__content + img
{
  min-height: 100%;
}
section {
  //height: 100%;
  //width: 100%;
  min-height: 100vh;
  height: 100vh;
  @media (max-width: $breakpoint__medium){
    height: auto;
    min-height: unset;
  }

  &.dark__bg {
    background-color: $gray__dark;
    color: $white;
  }

  &.light__bg {
    background-color: $white;
    color: $gray__dark;
  }

  &.primary__bg {
    background-color: $primary_color;
    color: $white;
  }
}
.section {

  $this: &;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    $this: &;

    &.flex-sm-row-reverse
    {
      .image.section__article
      {
        order: 2;
        @media(min-width: $breakpoint__medium) {
          order:1
        }
      }
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__with__bg {
    //background-image: url('../images/bg-pattern.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;
  }

  &__article
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    flex: 1 1 100%;
    $this: &;

    &.image
    {
      background-color: $secondary_color;
    }

    @media(min-width: $breakpoint__medium)
    {
      flex: 1;
    }

    h2 {
      font-weight: $font_extra_bold;
      line-height: 1;
      @media(min-width: $breakpoint__extra__large) {
        font-size: $heading__large;
      }
    }

    .lead {
      text-transform: capitalize;
      @media(min-width: $breakpoint__extra__large) {
        font-size: 2em;
        margin-bottom: $gutter__space * 2;
      }
    }


    &__specialities
    {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      grid-gap: 1px;

      @media(max-width: $breakpoint__extra__small) {
        grid-template-columns: 1fr;
      }
      &__item
      {
        padding: $gutter__space;
        max-width: 25em;
        @media(max-width: $breakpoint__small) {
          margin: auto;
        }
      }

      &__icon
      {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 6em;
        background: transparent;
        margin: 0 auto 15px auto;
      }

      &__title
      {
        font-weight: $font_semi_bold;
        color: $secondary_color;
        font-size: 1.2em;
        margin-bottom: 1em;
      }

    }
  }
}


.fp-viewing-home-0
{
  #scrollToTop {
    opacity: 0;
    visibility: hidden;
  }
}


#scrollToTop
{
  transition: ease-in-out 0.5s;
  transition-delay: 0.6s;
  position: fixed;
  right: 10px;
  bottom: -150px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 200;
}



.fp-viewing-about,
.fp-viewing-about_details,
.fp-viewing-work,
.fp-viewing-services,
.fp-viewing-services_details,
.fp-viewing-contact
{
  #scrollToTop
  {
    opacity: 1;
    visibility: visible;
    bottom: 10px;
  }

  header {
    .navbar {
      top: 0px;
      @media(max-width: $breakpoint__medium) {
        top: 0;
      }
      .container {
        .navbar-brand,
        button.btn-primary {
          bottom: 1px;
        }
      }
    }
  }

  .topbar
  {
    top: -200px;
  }


}


.container {
  max-width: 100%;
  @media(min-width: $breakpoint__extra__large) {
    max-width: $container__max__width__extra__large;
  }
}

.p__100 {
  padding: 100px;
}

.p__50 {
  padding: 50px;
}


img {
  max-width: 100%;
}

.max__800
{
  max-width: 30em;
  @media(min-width: $breakpoint__extra__large) {
    max-width: 40em;
  }
}

.max__500
{
  max-width: 500px;
}


.max__800, .max__500{
  @media(max-width: $breakpoint__medium) {
    max-width: 100%;
  }
}

.overlay__content
{
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: $white;
  line-height: 1.6;
  flex-direction: column;
  margin: auto;
  align-self: center;

  .contact__details
  {
    a { color: $orange; }
  }

  .oc{
    $this: &;
    &__title
    {
      text-transform: capitalize;
      font-size: 1.8em;
      margin-bottom: $gutter__space/2;
      @media(min-width: $breakpoint__extra__large) {
        font-size: 2.4em;
      }
      &--bold
      {
        font-weight: $font_extra_bold;
      }
    }
  }

  address
  {
    margin: $gutter__space 0;
    span {
      &:nth-child(1)
      {
        font-weight: normal;
      }
    }
  }

  address, .contact__details
  {
    span
    {
      display: block;
      font-weight: $font_extra_light;
    }
  }

  .btn
  {
    display: inline-block;
    max-width: 200px;
    border-radius: 40px;
    text-transform: uppercase;
    margin-top: 1em;
  }
}


/* contact form */


.form__title
{
  font-size: 2em;
  line-height: 80px;
  text-transform: capitalize;
  color: $secondary_color;

  margin-bottom: 15px;
  @media(min-width:$breakpoint__extra__large) {
    font-size: 40px;
    margin-bottom: 1em;
  }
}

.form-control.form-control
{
  border-radius: 0;
  border:0;
  border-bottom: 1px solid #D8D8D8;
  font-weight: $font_extra_light;
  text-transform: capitalize;
  font-size: 1.4em;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group.form-group
{
  margin-bottom: 3em;
  position: relative;

  label
  {
    left: 13px;
    top: 8px;
    font-size: 22px;
    position: absolute;
    text-transform: capitalize;
    margin: 0;
    @include transition(0.4s);
  }

}

::placeholder {
  color: #D8D8D8;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #D8D8D8;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #D8D8D8;
}

#contact
{
  .section__inner
  {
    .section__article.image
    {
      order: 1;
      @media(min-width:$breakpoint__medium) {
        order: -1;
      }
    }
  }
}

.text-primary.text-primary
{
  color: $primary_color !important;
}


.fp-controlArrow.fp-prev {
  left: 90%;
  border-width: 10px 10px 10px 0;
  border-color: transparent $primary_color transparent transparent;

  &:after
  {
    content: '';
    position: absolute;
    width: 40px;
    height: 5px;
    background: $primary_color;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
  }
}

.fp-controlArrow.fp-prev,
.fp-controlArrow.fp-next
{
  top:100%;
  @media(max-width: $breakpoint__medium) {
    display: none;
  }
}

.fp-controlArrow.fp-next
{
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent $primary_color;
  &:before
  {
    content: "";
    position: absolute;
    width: 40px;
    height: 5px;
    background: $primary_color;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
  }
}


// full page for mobile // css overwrite

@media(max-width: $breakpoint__medium) {
  .fp-section.fp-section
  {
    height: unset !important;
  }

  .fp-slidesContainer.fp-slidesContainer
  {
    width: unset !important;
    float: none !important;
  }

  .fp-section.fp-table, .fp-slide.fp-table
  {
    width: 100% !important;
  }

  .section__article.p__50.section__article.p__50
  {
    padding: 25px;
  }
  .section__article.p__100.section__article.p__100
  {
    padding: 50px 25px;
  }
}

